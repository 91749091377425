
import Box from '@mui/material/Box';
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import logo from './images/NeholaHeader.png'
import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material";
import BuilderSignUp from './BuilderSignUp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { API_URL } from "./constants";
import axios from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Header(props) {
 let mailbuilderId;
 const login_id = localStorage.getItem('login_id')
 console.log("login_id",login_id); 
    const menus = [
        { label: "Universal Calculator", link: "/" },
        { label: "Canada Mortgage", link: "/canada-mortgage " },     
        { label: "Nehola", link: "/projects " },    
        { label: "Blog", link: "/blog" }, 
        { label: "About us", link: "/about-us" },
        { label: "Contact us", link: "/contact-us" }
    ];
    const [isTermsAndCondition, setIsTermsAndCondition] = useState(false);
    const [thankYouPopUp, setThankYouPopUp] = useState(false);
    const [resetPopUp, setResetPopUp] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [resetConfirmPopUp, setResetConfirmPopUp] = useState(false);
    const [isSignOutPopUp, setIsSignOutPopUp] = useState(false);
    const [signInPopUp, setSignInPopup] = useState(false);
    const [signUpPopUp, setSignUpPopUp] = useState(false);
    const [verificationPopUp, setVerificationPopUp] = useState(false);
    const handleSignUpButton = () => {
      setSignInPopup(true);
  };
  const [emailForPasswordChange, setEmailForPasswordChange] = useState('');
  const [changePasswordPopUp, setChangePasswordPopUp] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    //console.log( queryParams.get('listingagentid'))
    // Access the query parameter and convert it to a boolean
    if (queryParams.get('changePassword') === 'true') {
      setChangePasswordPopUp(true);
    }
    if (queryParams.get('signIn') === 'true') {    
      fetchData() 
      setSignInPopup(true);
    }
    const emailParam = queryParams.get('email');
    if (emailParam) {
      setEmailForPasswordChange(emailParam);
    }
  }, []); // Use an empty dependency array to ensure this effect runs only once

  async function fetchData() { 
    const queryParams = new URLSearchParams(window.location.search); 
    const listingagentid = queryParams.get('listingagentid');
    localStorage.setItem('listing_agent_id', listingagentid);
    const myprofilelisting_agent_id = localStorage.getItem('listing_agent_id');

try {
  const response = await axios.post(`${API_URL}/update-loginstatus`, {
    listingagentid: listingagentid // Send it as a plain integer, not in an object
  });
} catch (error) {
  console.error('Error:', error.message);
}

      }
      
        useEffect(() => {
          fetchData();
        }, [signInPopUp]);

const [isOpen, setIsOpen] = useState(false);

const handleMenuToggleDropDown = () => {
  setIsOpen(!isOpen);

};
const handleMenuItemClick = (value) => {
  
    if (value === "myprofile") {
      window.location.href = '/edit-profile';
        setEditProfile(true)        
      }  
      // else if (value === "Addbuilders") {
      //   window.location.href = '/add-builder-details';
      // }     
      else if (value === "myprojects") {
        window.location.href = '/personal-projects';
      } 
      else if (value === "signout") {
        setIsSignOutPopUp(true);
      }
  setIsOpen(false);
}
const handleSignoutConfirm = async() => {
  try {
    const response = await axios.post(`${API_URL}/update-signout`, {          
      login_id: login_id,
  });
} 
catch (error) {
    console.error('Error:', error.message);
}
  localStorage.removeItem('listing_agent_id'); 
  localStorage.removeItem('myprofilelisting_agent_id'); 
  localStorage.removeItem('builder'); 
  localStorage.removeItem('builder_id');
  localStorage.removeItem('user_name');
  localStorage.removeItem('token');
  window.location.href = '/projects';


};

const closeSignOutPopup = () => {
  setIsSignOutPopUp(false);

};
const userName = localStorage.getItem('user_name');
//console.log("buildername",userName)
  // Split the user's name by spaces and take the first word
  const firstName = userName ? userName.split(' ')[0] : '';

return (
    <Grid container sx={{ width: '100%', height: '100%', margin: 0, }}>
            <AppBar position="static" sx={{ background: " #504FA4", height: "72px", width: '100%', boxShadow:'none' }}>
                <Box sx={{ display: "flex", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                 height: "72px" }}>
                    <Box sx={{
                        display: "flex", width: { md: '10%', sm: '12%' }, flexDirection: 'column', justifyContent: 'center',
                        alignItems: 'center',marginRight:'5%',
                    }}>
                        <img src={logo} alt="logo" onClick={() => { window.location.href = '/projects' }}
                         style={{ height: '80%', width: '90%', cursor:'pointer'}} />
                    </Box>
                    <Box sx={{
                        display: "flex", width: '80%', flexDirection: 'row', justifyContent: 'left', alignItems: 'left',
                        gap:'5%',
                    }}>
                        {menus.map((menu) => (
                            <Button
                                key={menu.label}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    color: 'white', 
                                    textTransform: "capitalize",textDecoration: menu.label === props.currentPage ? 'underline' :'none', textUnderlineOffset: "7px", 
                                     fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400",
                                    '&:hover': {  fontWeight: "600", bgcolor: '#504FA4' ,textDecoration: menu.label === props.currentPage ? 'underline' :'none', }
                                }}>
                                {menu.label}
                            </Button>
                        ))}
                    </Box>
                   
                    <Box sx={{
                        display: "flex", width: { md: '15%', sm: '12%' }, flexDirection: 'column', justifyContent: 'center',
                        alignItems: 'right',
                    }}>
                  {localStorage.getItem('user_name') === null &&      <Button  onClick={handleSignUpButton} variant="contained" sx={{
                        color: "#000", height: '30px',  width:'90%', borderRadius: "12px", background: '#F8A81D', border: "1px solid #F8A81D",
                        fontFamily: "Roboto", textTransform: "none", fontStyle: "normal", fontWeight: "500", fontSize:{ md: "14px", sm: "14px", xs: "12px" } ,
                         '&:hover': { bgcolor: "#FF8C00", color: '#000',border: "1px solid #FF8C00", }
                       
                    }}>
                    Listing agent sign in
                    </Button>}  

                    {/* {! builderName &&     <Button  onClick={handleToggleDropdown} variant="text" sx={{
                        color: "#EB5648", height: '30px',  width:{ md:'158px', sm:'158px' }, borderRadius: "12px", background: 'none', border: "none",
                        fontFamily: "Roboto", textTransform: "none", fontStyle: "normal", fontWeight: "600", fontSize:{ md: "16px", sm: "16px", xs: "12px" } , '&:hover': { bgcolor: "none", color: '#EB5648',border: "none", }                       
                    }}>                   
                  </Button> }  */}

{localStorage.getItem('user_name') !== null &&  <Box className="dropdown__toggle" onClick={handleMenuToggleDropDown}>
<Button  variant="text" sx={{
                        color: "#fff", height: '30px', width:{ md:'158px', sm:'158px' },  borderRadius: "12px", background: 'none', border: "none",
                        fontFamily: "Roboto", textTransform: "none", fontStyle: "normal", fontWeight: "600", fontSize:{ md: "16px", sm: "16px", xs: "12px" } , '&:hover': { bgcolor: "none", color: '#fff',border: "none", }                       
                    }}> 
                    <AccountCircleOutlinedIcon sx={{paddingRight:'8px', fontSize:'32px'}} />
                    {firstName}    <ExpandMoreIcon sx={{paddingLeft:'8px', fontSize:'32px'}}/>         
                  </Button>  

          </Box>}
          {isOpen && (
            <Box className="dropdown__menu" sx={{
              position: 'absolute', right: '70px',top:'60px', fontFamily: 'poppins', fontSize: '12px', fontWeight: '400',
              lineHeight: '20px', width: '150px', background: '#F8F8F8', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex: 1,
              color: '#2F2F2F', padding: '4px',
            }}>
     <style>
                {`
     .dropdown__menu .MuiMenuItem-root:hover {
      background-color: #504FA4 !important;
       color: white;
     }
   `}
              </style>
              <MenuItem onClick={() => handleMenuItemClick("myprofile")} value="option1" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                <PersonIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                My profile
              </MenuItem>
              {/* <MenuItem onClick={() => handleMenuItemClick("Addbuilders")} value="option1" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                <AddBusinessIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                Add builders
              </MenuItem> */}
              <MenuItem onClick={() => handleMenuItemClick("myprojects")} value="option1" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                <DriveFolderUploadOutlinedIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                My  projects
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("signout")} value="option2" style={{
                fontFamily: 'poppins', fontSize: '14px',
                fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
              }}>
                <LogoutIcon style={{ width: '12px', height: '14px', marginRight: '10px' }} />
                Sign out
              </MenuItem>
            </Box>

          )}

                    </Box>
                   

                </Box>
            </AppBar>
            {signUpPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange}
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp} />
      )}
       {signInPopUp && (
       <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
       signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
       changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
     resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
     resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
     emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
     isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
     setVerificationPopUp={setVerificationPopUp}/>
      )}
 {changePasswordPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}
       {resetPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}

{resetConfirmPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange}
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}

{thankYouPopUp && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}

{isTermsAndCondition && (
        <BuilderSignUp signInPopUp={signInPopUp} setSignInPopup={setSignInPopup} 
        signUpPopUp={signUpPopUp} setSignUpPopUp={setSignUpPopUp} editProfile={editProfile} setEditProfile={setEditProfile} 
        changePasswordPopUp={changePasswordPopUp} setChangePasswordPopUp={setChangePasswordPopUp}
      resetPopUp={resetPopUp} setResetPopUp={setResetPopUp} thankYouPopUp={thankYouPopUp} setThankYouPopUp={setThankYouPopUp}
      resetConfirmPopUp={resetConfirmPopUp} setResetConfirmPopUp={setResetConfirmPopUp}
      emailForPasswordChange={emailForPasswordChange} setEmailForPasswordChange={setEmailForPasswordChange} 
      isTermsAndCondition={isTermsAndCondition} setIsTermsAndCondition={setIsTermsAndCondition} verificationPopUp={verificationPopUp}
      setVerificationPopUp={setVerificationPopUp}/>
      )}

{isSignOutPopUp && (

<Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2'
  }}
>
  <Box
    sx={{
      position: 'absolute',
    
      width: '378px',
      height: '202px',
      backgroundColor: '#fff',

      border: '1px solid #CFC9C9',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
      zIndex: '3'
    }}
  >
    {/* <IconButton style={{
      color: '#939393', position: 'absolute', width: '20px', height: '20px',
      left: '347px', top: '12px'
    }} >
      <HighlightOffIcon onClick={closeSignOutPopup}  ></HighlightOffIcon>
    </IconButton> */}

    <Typography style={{
      fontFamily: "poppins", fontSize: "16px", lineHeight: "21px", fontWeight: "600", color: '#313131', width: "290px",
      textAlign: "center"
    }}>Sign out your account</Typography>
    <Typography style={{
      fontFamily: "poppins", fontSize: "14px", lineHeight: "21px", fontWeight: "400", color: '#313131', width: "290px",
      textAlign: "center",paddingTop:'20px'
    }}>Are you sure you would like to sign out
    your account?</Typography>
    <Box sx={{ display: 'flex', flexDirection: "row", marginTop: '30px' }}>
      <button size='small' onClick={closeSignOutPopup} style={{
        width: '77px', height: '32px', fontFamily: 'Poppins', fontStyle: 'normal',
        fontWeight: '400', fontSize: '14px', lineHeight: '21px', marginRight: '24px', textAlign: 'center', color: '#F8A81D',
        background: '#Fff', border: '1px solid #F8A81D', borderRadius: '4px',cursor:'pointer',
        '&:hover': { bgcolor: "#FFf", color: '#F8A81D',border: "1px solid #FF8C00" },
      }} >Cancel</button>
       <button size='small'   onClick={ handleSignoutConfirm} style={{
        width: '77px', height: '32px', fontFamily: 'Poppins',
        fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
        background: '#504FA4', border: '1px solid #504FA4', borderRadius: '4px',cursor:'pointer', 
        '&:hover': { bgcolor: "#3A3990", color: '#313131',border: "1px solid #3A3990" },
      }}  >Sign out </button>
   
      {/* <button size='small'  onClick={ handleSignoutConfirm} style={{
        width: '77px', height: '32px', fontFamily: 'Poppins',
        fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '21px', textAlign: 'center', color: '#fff',
        background: '#F8A81D', border: '1px solid #F8A81D', borderRadius: '4px',cursor:'pointer'
      }} onMouseEnter={(e) => {
        e.currentTarget.style.background = '#FF8C00';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.background = '#F8A81D';
      }} >Sign out </button> */}
    </Box>
  </Box>
</Box>
)}
</Grid>
  );

}

export default Header;
